<template>
  <main-layout page-title="Welcome To Teepster">
    <div class="ion-padding">
      <h4 class="ion-padding">Login</h4>
      <form data-cy="sign-in-form" @submit.prevent="login">
        <ion-item>
          <ion-label position="stacked">Email</ion-label>
          <ion-input
            type="email"
            placeholder="E-mail"
            v-model="email"
            autocomplete="on"
            clearInput
            required
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Password</ion-label>
          <ion-input
            type="password"
            placeholder="Password"
            v-model="password"
            autocomplete="on"
            clearInput
            required
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Remember Me</ion-label>
          <ion-checkbox
            slot="end"
            :modelValue="rememberMe"
            @update:modelValue="rememberMe = $event"
          >
          </ion-checkbox>
        </ion-item>

        <ion-button class="ion-margin-vertical" expand="block" type="submit"
          >Sign In</ion-button
        >
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col>
              <ion-button
                size="small"
                expand="block"
                color="light"
                type="button"
                >Register?</ion-button
              >
            </ion-col>
            <ion-col>
              <ion-button
                size="small"
                class="ion-padding-start"
                expand="block"
                color="light"
                type="button"
                >Forgot Password?</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </div>
  </main-layout>
</template>

<script>
import session from "../modules/session";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
} from "@ionic/vue";

export default {
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCheckbox,
  },
  data: () => ({
    email: null,
    password: null,
    rememberMe: true,
  }),
  methods: {
    async login() {
      if (this.email == null && this.password == null) {
        this.$notify({
          type: "error",
          text: "Please provide an email and password.",
        });
        return;
      }
      this.$auth
        .login({
          data: {
            email: this.email,
            password: this.password,
          },
          withCredentials: true,
          redirect: "/leagues",
          staySignedIn: this.rememberMe,
          rememberMe: this.rememberMe,
          fetchUser: true,
        })
        .then((r) => {
          session.setUserData(r.data);
          this.$router.replace("/leagues");
        });
    },
  },
};
</script>